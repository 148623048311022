<template>
  <div class="page1">
    <div class="content">
      <!-- 搜索条件区域 -->
      <div class="searchWrapper bgFFF">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item label="商家名称：" prop="stores">
            <el-select v-model.trim="storeName" filterable size="15">
              <el-option label="请选择" value=""></el-option>
              <el-option
                :label="value.storeName"
                :value="value.storeName"
                v-for="value in storeList"
                :key="value.storeId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="交易类型:">
            <el-select v-model.trim="formInline.tradeType" filterable>
              <el-option label="全部" value></el-option>
              <el-option label="充值" value="2"></el-option>
              <el-option label="退款" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Trading_Time')">
            <el-date-picker
              v-model="datetimerange"
              type="datetimerange"
              range-separator="至"
              :editable="false"
              :clearable="false"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >查询
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-delete"
              @click="clearTable()"
              :loading="loading"
              >清空
            </el-button>
            <!-- 导出文件权限  $route.meta.authority.tabs.finParkingCardManage.button.export -->
            <el-button
              type="primary"
              plain
              icon="el-icon-download"
              @click="exportFile"
              >导出
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          border
          header-cell-class-name="header-call-style"
          v-loading="loading"
          stripe
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            align="center"
            :index="indexMethod"
            width="80"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper" v-show="total > 0">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { exportExcelNew, dateFormat } from "@/common/js/public";
export default {
  name: "merchantRechargeRecords",
  data() {
    const start = new Date();
    const end = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    return {
      formInline: { operationId: "", tradeType: "" },
      storeName: "",
      datetimerange: [
        dateFormat(start, "yyyy-MM-dd HH:mm:ss"),
        dateFormat(end, "yyyy-MM-dd HH:mm:ss"),
      ],
      loading: false,
      tableData: [],
      storeList: [],
      tableCols: [
        {
          prop: "storeName",
          label: "商家名称",
          width: "165",
        },
        {
          prop: "transactionType",
          label: "交易类型",
          width: "165",
        },
        {
          prop: "transactionMoney",
          label: "交易金额",
        },
        {
          prop: "beforeTransactionMoney",
          label: "交易前余额",
        },
        {
          prop: "afterTransactionMoney",
          label: "交易后余额",
        },
        {
          prop: "transactionChannel",
          label: "交易渠道",
        },
        {
          prop: "payChannel",
          label: this.$t("list.Payment_channels"),
        },
        {
          prop: "transactionTime",
          label: "交易时间",
          width: "165",
        },
      ],
      total: 0,
      pageSize: 15,
      page: 1,
    };
  },
  methods: {
    getRoleList() {
      this.$axios.get("/acb/2.0/store/listStores").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.storeList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleSelect(item) {
      this.formInline.operationId = item.operationId;
      // console.log(item);
    },
    searchData() {
      this.$axios
        .get("/acb/2.0/storeRechargeDiscountRule/rechargeRefundRecord", {
          data: {
            storeName: this.storeName || "",
            transactionType: this.formInline.tradeType,
            startTime: this.datetimerange[0],
            endTime: this.datetimerange[1],
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.parkId = "";
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    exportFile() {
      exportExcelNew(
        "/acb/2.0/storeRechargeDiscountRule/rechargeRefundRecord/export",
        {
          storeName: this.storeName || "",
          transactionType: this.formInline.tradeType,
          startTime: this.datetimerange[0],
          endTime: this.datetimerange[1],
        }
      );
    },
    indexMethod(index) {
      return index + 1;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    clearTable() {
      this.storeName = "";
      this.formInline.tradeType = "";
      const start = new Date();
      const end = new Date();
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      this.datetimerange = [
        dateFormat(start, "yyyy-MM-dd HH:mm:ss"),
        dateFormat(end, "yyyy-MM-dd HH:mm:ss"),
      ];
    },
  },
  mounted() {
    this.searchData();
    this.getRoleList();
  },
  created() {
    console.log("aaaaaaaaaaaaaaaaaaa");
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.conborder {
  border-top: 1px solid #C0CCDA;
  background: #FFFFFF;
  overflow: hidden;
  padding: 10px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    padding: 22px 22px 0;
    margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

/deep/.el-table {
  width: 100% !important;
}
</style>
